import ReactModal, { Props } from 'react-modal'
import classnames from 'classnames'

import styles from './ModalSidebarContainer.module.css'

// This will work with both Next.js and Storybook
ReactModal.setAppElement('#__next,#root')

// Reset default styles, so we can use our own
ReactModal.defaultStyles = {}

export type ModalSidebarContainer = Props & {
  title?: string
  onExit: () => void
  closeable?: boolean
}

const ModalSidebarContainer = ({
  title,
  children,
  onExit,
  closeable = true,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
  overlayClassName,
  bodyOpenClassName,
  htmlOpenClassName,
  className,
  ...rest
}: ModalSidebarContainer) => (
  <ReactModal
    contentLabel=
    onRequestClose={onExit}
    shouldCloseOnEsc={shouldCloseOnEsc ?? closeable}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick ?? closeable}
    overlayClassName={classnames(styles.overlay, overlayClassName)}
    bodyOpenClassName={classnames(styles.bodyOpen, bodyOpenClassName)}
    htmlOpenClassName={classnames(styles.htmlOpen, htmlOpenClassName)}
    className={classnames(styles.container, className)}
    {...rest}>
    <div className={styles.content}>{children}</div>
  </ReactModal>
)

/* Convenience components for sidebar content */

interface ContentComponentProps {
  className?: string
  children: React.ReactNode
}

const [ModalSidebarHeader, ModalSidebarBody, ModalSidebarFooter] = ['header', 'body', 'footer'].map(name => {
  const Component = ({ className, children }: ContentComponentProps) => (
    <div className={classnames(className, styles[name])}>{children}</div>
  )

  return Component
})

export { ModalSidebarHeader, ModalSidebarBody, ModalSidebarFooter }
export default ModalSidebarContainer
