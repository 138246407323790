import React from 'react'
import LinkList from 'components/LinkList'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'
import GridContainer from 'modules/marketplace/layout/components/GridContainer'

import styles from './MarketingFooter.module.css'

import Logo from 'components/Logo'

const linklists = [
  {
    title: 'For Wholesalers',
    links: [
      {
        label: 'Sell on ����vlog����',
        href: '/wholesaler'
      },
      {
        label: 'Seller Help',
        href: `${process.env.NEXT_PUBLIC_HELP_URL}/en/collections/3413401-wholesalers`,
        isExternal: true
      },
      {
        label: 'Pricing',
        href: '/pricing'
      },
      {
        label: 'MYOB Integration',
        href: '/integrations/myob'
      },
      {
        label: 'Xero Integration',
        href: '/integrations/xero'
      }
    ]
  },
  {
    title: 'For Retailers',
    links: [
      {
        label: 'Buy on ����vlog����',
        href: '/retailer'
      },
      {
        label: 'Buyer Help',
        href: `${process.env.NEXT_PUBLIC_HELP_URL}/en/collections/3413400-buyers-retailers`,
        isExternal: true
      }
    ]
  },
  {
    title: 'For Sales Reps',
    links: [
      {
        label: 'Sell on iPad',
        href: '/for-reps'
      },
      {
        label: 'Sales Rep Help',
        href: `${process.env.NEXT_PUBLIC_HELP_URL}/en/collections/3472127-sales-reps`,
        isExternal: true
      }
    ]
  },
  {
    title: 'Support',
    links: [
      {
        label: 'Contact Support',
        href: '/contact'
      },
      {
        label: 'Help',
        href: `${process.env.NEXT_PUBLIC_HELP_URL}`,
        isExternal: true
      }
    ]
  }
]

const MarketingFooter = () => (
  <footer className={styles.footer}>
    <Container>
      <div className={styles.logoContainer}>
        <Logo className={styles.logo} />
      </div>
      <GridContainer>
        {linklists.map((linkList, index) => (
          <div key={index}>
            <h4>{linkList.title}</h4>
            <LinkList className={styles.linklist} links={linkList.links} />
          </div>
        ))}
      </GridContainer>
    </Container>
  </footer>
)

export default MarketingFooter
